import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { formatDistanceToNowStrict } from 'date-fns';
import locale from 'date-fns/locale/en-US';
import ReactPaginate from 'react-paginate';
import useService from 'hooks/useService';
import axios from 'axios';
import IResponseGetChains from 'constants/interfaces/response-getChains';
import IHistoryConfig from 'constants/interfaces/historyConfig';
import './styles.scss';
import { ReactComponent as ArrowLeft } from '../assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRight } from '../assets/icons/arrow-right.svg';
import { ReactComponent as NoHistorySVG } from '../assets/icons/no_found.svg';
import { toTxHash, toTxUrl } from 'utils/url';
import Spinner from 'components/Spinner';
import Items, { IHistoryRow } from './components';
import { formatDistance } from 'utils/formatData';
import IChainConfig from 'constants/interfaces/chainConfig';

type HistoryResponse = {
	totalCount: number;
	txs: IHistoryConfig[];
};

type HistoryData = {
	totalCount: number;
	txs: IHistoryRow[];
};

export default function History({
	itemsPerPage = 8,
}: {
	itemsPerPage?: number;
}) {
	const [_, { respErrorHandler }] = useService();

	const [chains, setChains] = useState<IChainConfig[]>();

	const [historyData, setHistoryData] = useState<HistoryData | null>(null);
	const [pageCount, setPageCount] = useState(0);
	const [page, setPage] = useState(1);
	const [loading, setLoading] = useState<boolean>(true);

	useEffect(() => {
		const controller = new AbortController();

		(async () => {
			try {
				const chains = (
					await axios.get<IResponseGetChains>(
						'/secure/bridge/public/chains',
					)
				).data;

				setChains(chains);
			} catch (e) {
				respErrorHandler(e);
			}
		})();
		return () => {
			controller.abort();
		};
	}, []);

	useEffect(() => {
		const controller = new AbortController();

		if (!chains) return;

		(async () => {
			try {
				const data = await axios
					.get<HistoryResponse>('/secure/bridge/txHistory', {
						params: { size: itemsPerPage, page: page },
					})
					.then((res) => {
						const txs = res.data.txs.map((item) => {
							const fromChain = chains.find((chain) => {
								return (
									item.from.chainInfo.chainType ===
										chain.chainType &&
									item.from.chainInfo.chainId ===
										chain.chainId
								);
							});
							const fromChainTxHash = fromChain
								? toTxHash(
										fromChain.chainType,
										item.from.txHash,
								  )
								: item.from.txHash;

							const toChain = chains.find((chain) => {
								return (
									item.to.chainInfo.chainType ===
										chain.chainType &&
									item.to.chainInfo.chainId === chain.chainId
								);
							});
							const toChainTxHash =
								toChain &&
								item.to.txHash &&
								toTxHash(toChain.chainType, item.to.txHash);

							const date = formatDistanceToNowStrict(
								new Date(item.date),
								{
									addSuffix: true,
									locale: {
										...locale,
										formatDistance,
									},
								},
							);
							return {
								action: item.action,
								amount: item.tokenAmount,
								date,
								fee: item.fee,
								from: {
									chainName: fromChain?.name,
									txHash: fromChainTxHash,
									txUrl:
										fromChain &&
										fromChainTxHash &&
										toTxUrl(
											fromChain.chainType,
											fromChain.blockExplorerUrl,
											fromChainTxHash,
										),
								},
								status: item.status,
								to: {
									chainName: toChain?.name,
									txHash: toChainTxHash,
									txUrl:
										toChain &&
										toChainTxHash &&
										toTxUrl(
											toChain.chainType,
											toChain.blockExplorerUrl,
											toChainTxHash,
										),
								},
								token: item.token,
								tag: item.tag,
							};
						});
						return {
							totalCount: res.data.totalCount,
							txs: txs,
						};
					});
				setHistoryData(data);
			} catch (e) {
				respErrorHandler(e);
			}
		})();
		return () => {
			controller.abort();
		};
	}, [chains, page]);

	useEffect(() => {
		historyData ? setLoading(false) : setLoading(true);
		historyData &&
			setPageCount(Math.ceil(historyData.totalCount / itemsPerPage));
	}, [historyData, itemsPerPage]);

	const handlePageClick = (event: { selected: number }) => {
		setPage(event.selected + 1);
	};

	const hasData = historyData?.totalCount;
	return (
		<div className={clsx(hasData ? 'history' : 'no_history')}>
			{loading ? (
				<Spinner />
			) : hasData ? (
				<>
					<div className="history-table-line"></div>
					<Items currentItems={historyData?.txs} />
					<ReactPaginate
						nextLabel={<ArrowRight />}
						onPageChange={handlePageClick}
						pageRangeDisplayed={7}
						marginPagesDisplayed={0}
						pageCount={pageCount}
						previousLabel={<ArrowLeft />}
						pageClassName="page-item"
						pageLinkClassName="page-link"
						previousClassName="page-item"
						previousLinkClassName="page-link"
						nextClassName="page-item"
						nextLinkClassName="page-link"
						breakLabel="..."
						breakClassName="page-item"
						breakLinkClassName="page-link"
						containerClassName="pagination"
						activeClassName="active"
						renderOnZeroPageCount={undefined}
					/>
				</>
			) : (
				<>
					<NoHistorySVG />
					<p>No transactions found</p>
				</>
			)}
		</div>
	);
}
