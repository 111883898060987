import ENetworks from 'constants/enums/networks';
import IConfig from 'constants/types/config';

const config: IConfig = {
	// API_URL: 'http://3.120.193.165:8080/',
	API_URL: 'https://bridge.api.alphacarbon.network/',
	ONBOARD_API_KEY: '',
	DEFAULT_NETWORK: ENetworks.leucine_100,
};

export default config;
