import 'constants/axios';
import Online from './views/Online';
import NetworkDisplay from './views/Online/components/NetworkDIsplay';
import styles from './styles.module.scss';
import { ethers } from 'ethers';
import { WalletState } from '@web3-onboard/core';
import useService from 'hooks/useService';
import { NetworkInfo, checkNetwork } from 'hooks/useNetwork';
import { useConnectWallet, useSetChain } from '@web3-onboard/react';
import OnlineContext from '../Bridge/views/Online/context';
import StatusDisplay from './views/Online/components/StatusDisplay';
import Branding from 'components/Branding';
// import IUser from 'constants/interfaces/user';

type Account = {
	id?: string;
	address: string;
	token?: string;
};

function Bridge() {
	const [{ wallet }, connect] = useConnectWallet();

	if (!wallet) return null;

	return <NetworkCheck wallet={wallet} />;
}

function NetworkCheck({ wallet }: { wallet: WalletState }) {
	const [{ chains }, setChain] = useSetChain();
	const [{ account }] = useService();

	const { network, networkList } = checkNetwork();
	const web3Provider = new ethers.providers.Web3Provider(wallet.provider);

	return !network || !account ? (
		<>
			<Branding />
			<div className={styles.root}>
				<NetworkDisplay
					type={network === false ? 'fail' : 'pending'}
					label={
						network === false
							? 'Network not supported'
							: 'Loading network configurations'
					}
					info={
						network === false
							? 'Please select one of the following networks.'
							: ''
					}
					networkList={networkList}
					setChain={setChain}
				/>
			</div>
		</>
	) : (
		<>
			<Branding />
			<Main
				web3Provider={web3Provider}
				network={network}
				accountInfo={account}
			/>
		</>
	);
}

type MainProps = { web3Provider: ethers.providers.Web3Provider } & {
	network: NetworkInfo;
} & { accountInfo: Account };

function Main({ web3Provider, network, accountInfo }: MainProps) {
	return (
		<div className={styles.root}>
			{accountInfo.token && accountInfo.id ? (
				<OnlineContext.Provider
					value={{
						token: accountInfo.token,
						id: accountInfo.id,
						address: accountInfo.address,
						web3Provider,
						...network,
					}}
				>
					<Online />
				</OnlineContext.Provider>
			) : (
				<StatusDisplay
					type="pending"
					label="Unlock Metamask Wallet"
					info="You may need to click the extension"
				/>
			)}
		</div>
	);
}

export default Bridge;
