import ETokens from 'constants/enums/tokens';

type ITokens = Record<string, ETokens>;

const MTokens: ITokens = {
	['USDA']: ETokens.USDT,
	['USDT']: ETokens.USDT,
	['TACT']: ETokens.ACT,
	['ACT']: ETokens.ACT,
};

export default MTokens;
