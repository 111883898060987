import type { WalletState } from '@web3-onboard/core';
import styles from './styles.module.scss';
import metamask from 'assets/images/metamask.svg';
import coinbase from 'assets/images/coinbase.svg';
import bnb from 'assets/images/bnb.svg';
import carbon from 'assets/images/carbon.svg';

interface ConnectOptions {
	autoSelect?: {
		label: string;
		disableModals: boolean;
	};
}

type walletSelectProps = {
	connect: (options?: ConnectOptions | undefined) => Promise<WalletState[]>;
};

function WalletSelect({ connect }: walletSelectProps) {
	const autoSelect = async (walletName: string) => {
		connect({ autoSelect: { label: walletName, disableModals: false } });
	};

	return (
		<div className={styles.container}>
			<div className={styles.root}>
				<h4>Select a Wallet</h4>
				<p>Please select a wallet to connect to this dapp:</p>
				<div className={styles.wallet}>
					<div
						className={styles.wallet_list}
						onClick={() => {
							autoSelect('MetaMask');
						}}
					>
						<img src={metamask} alt="MetaMask" />
						<p>MetaMask</p>
					</div>
					<div
						className={styles.wallet_list}
						onClick={() => {
							autoSelect('Coinbase Wallet');
						}}
					>
						<img src={coinbase} alt="coinbase" />
						<p>Coinbase Wallet</p>
					</div>
					<div
						className={styles.wallet_list}
						onClick={() => {
							autoSelect('Binance Smart Wallet');
						}}
					>
						<img src={bnb} alt="Binance" />
						<p>Binance Wallet</p>
					</div>
					<div
						className={styles.wallet_list}
						onClick={() => {
							autoSelect('Carbon Wallet');
						}}
					>
						<img src={carbon} alt="Carbon" />
						<p>Carbon Wallet</p>
					</div>
				</div>
				{/* #TODO: Add link to explain wallet */}
				{/* <a href="" className={styles.link}>
					What is a wallet?
				</a> */}
			</div>
		</div>
	);
}

export default WalletSelect;
