import { ButtonHTMLAttributes, ReactNode } from 'react';
import clsx from 'clsx';
import styles from './styles.module.scss';

type IProps = { children: ReactNode } & ButtonHTMLAttributes<HTMLButtonElement>;

function Button({ children, className, ...props }: IProps) {
	return (
		<button
			type="button"
			{...props}
			className={clsx(styles.root, className)}
		>
			{children}
		</button>
	);
}

export default Button;
