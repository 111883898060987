import axios from 'axios';
import config from 'config';

axios.defaults.baseURL = config.API_URL;

axios.interceptors.response.use(
	async (result) => {
		// #TODO: Map data if necessary
		// const { config } = result;

		return result;
	},
	async (error) => {
		const code = error.response.status;
		const message = error.response.data;

		const responseError = {
			isResponseError: true,
			code,
			message,
		};

		return Promise.reject(responseError);
	},
);
