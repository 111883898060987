import { ApiPromise, HttpProvider } from '@polkadot/api';
import ETokens from 'constants/enums/tokens';

const usdt_assdt_id = BigInt('187664640556126384513053853477161344711');

export const getAssetBridgeOutFee = async (
	aminoxRpcUrl: string,
	tokenType: ETokens,
	callback: (fee: bigint) => void,
) => {
	const provider = new HttpProvider(aminoxRpcUrl);
	const api = await ApiPromise.create({ provider: provider });

	try {
		if (tokenType === ETokens.ACT) {
			await api.query.erc20Extension.nativeBridgeOutFee((fee: bigint) => {
				callback(fee);
			});
		} else if (tokenType === ETokens.USDT) {
			await api.query.erc20Extension.assetBridgeOutFee(
				usdt_assdt_id,
				(fee: bigint) => {
					callback(fee);
				},
			);
		}
	} catch (e) {
		console.log('Failed to get fee:', e);
	}
	api.disconnect();
};
