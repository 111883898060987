import { useState } from 'react';
import useClickAwayListener from '@donnikitos/react-clickaway/hook';
import clsx from 'clsx';
import InputLabel from 'HOCs/InputLabel';
import { ReactComponent as ArrowDownSVG } from './assets/arrow-down.svg';
// import { ReactComponent as TickSVG } from './assets/tick.svg';
import styles from './styles.module.scss';

type IProps = {
	value: string;
	options: {
		value: IProps['value'];
		label: string;
		icon?: string;
	}[];
	error?: string;
	disabled?: boolean;
	placeholder?: string;
	onChange: (value: IProps['value']) => void;
};

function InputSelect({
	value,
	options,
	error,
	disabled,
	placeholder = 'Select',
	onChange,
}: IProps) {
	let withIcon: boolean, selected;
	if (disabled) {
		withIcon = false;
		selected = null;
	} else {
		withIcon = options.some((item) => !!item.icon);
		selected = options.find((item) => item.value === value);
	}

	const [inputRef, setInputRef] = useState<HTMLElement | null>(null);
	const [popperRef, setPopperRef] = useState<HTMLElement | null>(null);
	const [popperOpen, setPopperOpen] = useState(false);

	function placeIcon(iconSrc: string | undefined) {
		if (!withIcon) {
			return null;
		}

		if (!iconSrc) {
			return <div className={styles.icon} />;
		}

		return <img src={iconSrc} className={styles.icon} />;
	}

	useClickAwayListener(
		popperRef,
		() => {
			setPopperOpen(false);
		},
		[inputRef],
	);

	// useEffect(() => {
	// 	if (selected) {
	// 		return;
	// 	}

	// 	onChange('');
	// }, [options]);

	return (
		<div className={clsx(styles.root, [disabled && styles.disabled])}>
			<div
				className={clsx(styles.input, error && styles.input_hasError)}
				ref={setInputRef}
				onClick={() => {
					disabled
						? setPopperOpen(false)
						: setPopperOpen((prev) => !prev);
				}}
			>
				<div className={styles.body}>
					{placeIcon(selected?.icon)}
					{selected ? (
						<div>{selected.label}</div>
					) : (
						<div>{placeholder}</div>
					)}
				</div>
				<div className={styles.arrow}>
					<ArrowDownSVG />
				</div>
			</div>
			{popperOpen && (
				<div className={styles.popper} ref={setPopperRef}>
					{!disabled &&
						options.map((item) => {
							const selected = item.value === value;

							return (
								<div
									key={item.value}
									className={clsx(
										styles.option,
										selected && styles.selected,
									)}
									onClick={() => {
										onChange(item.value);
										setPopperOpen(false);
									}}
								>
									{placeIcon(item.icon)}
									<div className={styles.label}>
										{item.label}
									</div>
									{/* {selected && <TickSVG />} */}
								</div>
							);
						})}
				</div>
			)}
			<div className={styles.error}>{error}</div>
		</div>
	);
}

export default InputLabel(InputSelect);

export type { InputSelect };
