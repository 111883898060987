import React from 'react';
import clsx from 'clsx';
import ReactTooltip from 'react-tooltip';
import styles from './styles.module.scss';

type ITooltipProps = {
	className?: string;
	children: React.ReactNode;
	text: string;
};

function Tooltip({ className, children, text, ...props }: ITooltipProps) {
	return (
		<div>
			<p data-tip={text}>{children}</p>
			<ReactTooltip
				className={clsx(className, styles.root)}
				delayShow={200}
				{...props}
			/>
		</div>
	);
}

export default Tooltip;
