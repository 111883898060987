import ENetworks from 'constants/enums/networks';
import IConfig from 'constants/types/config';

import staging from './staging';
import production from './production';

const dev: IConfig = {
	// API_URL: 'http://192.168.50.153:8080/',
	API_URL: 'http://localhost:3030/',
	ONBOARD_API_KEY: '',
	DEFAULT_NETWORK: ENetworks.ropsten,
};

const configs = { dev, staging, production };
const configKey: keyof typeof configs =
	process.env.BUILD_MODE &&
	Object.keys(configs).includes(process.env.BUILD_MODE)
		? (process.env.BUILD_MODE as keyof typeof configs)
		: 'dev';

const config = configs[configKey];

export default config;
