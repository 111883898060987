const formatDistanceLocale: any = {
	lessThanXSeconds: '{{count}} secs',
	xSeconds: '{{count}} secs',
	halfAMinute: '30 secs',
	lessThanXMinutes: '{{count}} mins',
	xMinutes: '{{count}} mins',
	aboutXHours: '{{count}} hours',
	xHours: '{{count}} hours',
	xDays: '{{count}} days',
	aboutXWeeks: '{{count}} weeks',
	xWeeks: '{{count}} weeks',
	aboutXMonths: '{{count}} months',
	xMonths: '{{count}} months',
	aboutXYears: '{{count}} years',
	xYears: '{{count}} years',
	overXYears: '{{count}} years',
	almostXYears: '{{count}} years',
};

export const formatDistance = (
	token: number,
	count: number,
	options: {
		addSuffix?: any;
		includeSeconds?: boolean;
		comparison?: any;
	},
) => {
	options = options || {};

	const result = formatDistanceLocale[token].replace('{{count}}', count);
	if (options.addSuffix) {
		if (options.comparison > 0) {
			return 'in ' + result;
		} else {
			return result + ' ago';
		}
	}

	return result;
};
