import { Route, Routes, Navigate } from 'react-router-dom';
import { Web3OnboardProvider } from '@web3-onboard/react';
import initWeb3Onboard from 'constants/wallet/initWeb3Onboard';
import Bridge from 'Bridge';
import History from 'Historys';
import Header from '../components/Header';
import styles from './styles.module.scss';
import { NetworkProvider } from 'hooks/useNetwork';
import { ServiceProvider } from 'hooks/useService';
import Modal from 'react-modal';

Modal.setAppElement('#root');

if (process.env.BUILD_INFO) {
	console.log('Build Info:', process.env.BUILD_INFO);
}

function App() {
	return (
		<div className={styles.root}>
			<Web3OnboardProvider web3Onboard={initWeb3Onboard}>
				<NetworkProvider>
					<ServiceProvider>
						<Header />
						<Routes>
							<Route
								path="*"
								element={<Navigate to="/" replace />}
							/>
							<Route path="/" element={<Bridge />} />
							<Route path="/history" element={<History />} />
						</Routes>
					</ServiceProvider>
				</NetworkProvider>
			</Web3OnboardProvider>
		</div>
	);
}

export default App;
