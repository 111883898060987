import { formDataRef } from './../../index';
import { TransferFormProps } from '../..';
import { ethers } from 'ethers';
import EChainType, { EChainTypeIdx } from 'constants/enums/chainType';
import { decodeBase58Address } from 'utils/code';

type IArgs = TransferFormProps;

export async function submitAction({
	onSubmit,
	onSuccess,
	onError,
	onSend,
}: IArgs) {
	try {
		onSubmit();

		// const result = (
		// 	await axios.post<IResponseWithdraw>(
		// 		'/secure/bridge/withdrawal/toAddress',
		// 		{
		// 			tokenCode: formDataRef.current.symbol,
		// 			address: formDataRef.current.wallet,
		// 			chainId: formDataRef.current.chain,
		// 			value: formDataRef.current.amount,
		// 		},
		// 	)
		// ).data;
		// result.hashTransaction && onSend(result.hashTransaction);
		// result.hashTransaction && onSuccess();

		const { toChainInfo, token, address, amount, contract } =
			formDataRef.current;

		if (!toChainInfo || !token || !contract) return;
		const value = ethers.utils.parseUnits(
			String(Number(amount)),
			token.decimals,
		)._hex;

		const hexAddress =
			toChainInfo?.chainType === EChainType.tron
				? decodeBase58Address(address)
				: address;

		// console.log('contract', contract);
		// console.log('decimals', token!.decimals, await contract.decimals());
		// console.log('symbol', token!.symbol, await contract.symbol());

		const result = await contract.transferBridgedOut(
			hexAddress,
			value,
			toChainInfo!.chainId,
			EChainTypeIdx[toChainInfo.chainType],
			// #HACK: Fix gas price since someone use our chain id: 13371
			{ gasLimit: 150_000, gasPrice: 100_000_000_000 },
		);
		result.hash && onSend(result.hash);
	} catch (e) {
		console.log('error', e);
		onError();
		// if (axios.isAxiosError(e)) {
		// 	onError(e.response?.data.message);
		// }
	}
}
