import * as Yup from 'yup';

export const validation = Yup.object().shape({
	fromChain: Yup.string().required('Required'),
	toAddress: Yup.string().required('Required'),
	token: Yup.string().required('Required'),
	amount: Yup.number()
		.required('Required')
		.typeError('must be number')
		.min(0, 'amount must be greater than 0'),
});
