import styles from './styles.module.scss';

type IProps = { icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>> };

function Icon({ icon: SVG }: IProps) {
	// eslint-disable-next-line @typescript-eslint/no-var-requires

	return (
		<div className={styles.root}>
			<div>
				<SVG width={undefined} height={undefined} />
			</div>
		</div>
	);
}

export default Icon;
