export const TronLinkWarning = {
	title: 'TronLink wallet required',
	content: 'Please install TronLink wallet',
};

export const ConnectWalletWarning = {
	title: 'Connect Wallet',
	content: 'Please connect TronLink wallet',
};

export const NetworkWarning = {
	title: 'Wrong Network',
	content: 'Please switch the network in TronLink',
};
