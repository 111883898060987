import clsx from 'clsx';
import styles from './styles.module.scss';

type Tab<V> = {
	label: string;
	value: V;
};

type IProps<V> = {
	tabs: Tab<V>[];
	active: Tab<V>['value'];
	onChange: (active: Tab<V>['value']) => void;
	className?: string;
};

function TabNav<V>({ tabs, active, onChange, className }: IProps<V>) {
	return (
		<div className={clsx(styles.root, className)}>
			{tabs.map((item, i) => (
				<button
					key={item.label + i}
					type="button"
					className={clsx(active === item.value && styles.active)}
					onClick={() => onChange(item.value)}
				>
					{item.label}
				</button>
			))}
		</div>
	);
}

export default TabNav;
