import { useState, useEffect, Fragment } from 'react';
import clsx from 'clsx';
import axios from 'axios';
// import Dropdown from 'react-dropdown';
import { useNavigate, useLocation } from 'react-router-dom';
import { ethers } from 'ethers';
import useService from 'hooks/useService';
import IResponseGetTokens from 'constants/interfaces/response-getTokens';
import 'react-dropdown/style.css';
import styles from './styles.module.scss';
import './styles.scss';
import { useSetChain, useConnectWallet } from '@web3-onboard/react';
import { ReactComponent as LogoIconSVG } from 'assets/icons/logo.svg';
import { ReactComponent as HistoryIcon } from 'assets/icons/history.svg';
import { ReactComponent as TokenIcon } from 'assets/icons/account_balance_wallet.svg';
import { ReactComponent as ActIcon } from 'assets/icons/ACT.svg';
import { ReactComponent as USDTIcon } from 'assets/icons/USDT.svg';
import { ReactComponent as PancakeIcon } from 'assets/icons/pancake_icon.svg';
import ITokenConfig from 'constants/interfaces/tokenConfig';
// import Branding from 'components/Branding';
import MTokens from 'constants/maps/tokens';
import ETokens from 'constants/enums/tokens';
import {
	pancakeSwapBuyUrl,
	testnetPancakeSwapBuyUrl,
} from 'constants/pancakeswap';

// import { ReactComponent as LogoutIcon } from '../../assets/icons/logout.svg';

const addToken = async (
	web3Provider: ethers.providers.Web3Provider,
	token: ITokenConfig,
) => {
	const tokenAddress = token.contractAddress;
	const tokenSymbol = token.symbol;
	const tokenDecimals = token.decimals;
	const tokenType = MTokens[token.symbol];
	const tokenImage =
		tokenType === ETokens.USDT
			? 'https://alpha-carbon.github.io/brand-resources/tokens/usdt.png'
			: 'https://alpha-carbon.github.io/brand-resources/tokens/act.png';

	try {
		// wasAdded is a boolean. Like any RPC method, an error may be thrown.
		const wasAdded = await web3Provider.send('wallet_watchAsset', {
			type: 'ERC20', // Initially only supports ERC20, but eventually more!
			options: {
				address: tokenAddress, // The address that the token is at.
				symbol: tokenSymbol, // A ticker symbol or shorthand, up to 5 chars.
				decimals: tokenDecimals, // The number of decimals in the token
				image: tokenImage, // A string url of the token logo
			},
		} as unknown as any[]);
		if (wasAdded) {
			console.log('Token added');
		} else {
			console.log('Token not added');
		}
	} catch (error) {
		console.log(error);
	}
};

function Header() {
	const [{ connectedChain }] = useSetChain();
	// console.log(chains, connectedChain, settingChain, setChain);
	const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();
	const navigate = useNavigate();
	const location = useLocation();
	const [{ isSignedIn }] = useService();
	const [inHistory, setInHistory] = useState<boolean>(false);
	const [tokenShow, setTokenShow] = useState<boolean>(false);
	const [USDT, setUSDT] = useState<ITokenConfig>();
	const [ACT, setACT] = useState<ITokenConfig>();
	// const [tokenAddress, setTokenAddress] = useState<string>('');

	// const chainOptions = chains.map(({ label }) => {
	// 	return label;
	// });

	useEffect(() => {
		(async () => {
			try {
				const result = (
					await axios.get<IResponseGetTokens>(
						'/secure/bridge/public/tokens',
					)
				).data;
				const chainId = connectedChain
					? parseInt(connectedChain.id, 16)
					: null;

				const USDT_DATA = result.USDT;
				const USDTToken = USDT_DATA?.find((item) => {
					return item.chainInfo.chainId === chainId;
				});
				setUSDT(USDTToken);

				const ACT_DATA = result.ACT;
				const ACTToken = ACT_DATA?.find((item) => {
					return item.chainInfo.chainId === chainId;
				});
				setACT(ACTToken);
			} catch (e) {
				console.log(e);
			}
		})();
	}, [connectedChain]);

	useEffect(() => {
		location.pathname === '/history'
			? setInHistory(true)
			: setInHistory(false);
	}, [location]);

	// const _onChange = async (e: any) => {
	// 	const setChainId = chains.find(({ label }) => {
	// 		return e.value === label;
	// 	});
	// 	await setChain({
	// 		chainId: String(setChainId?.id),
	// 	});
	// };

	return (
		<>
			<div className={styles.header}>
				{inHistory && (
					<div
						className={styles.header_back}
						// className={styles.header_info_item}
						onClick={() => {
							navigate('/');
						}}
					>
						{/* <Branding mini={true} /> */}
						<LogoIconSVG />
						Bridge
					</div>
				)}
				{isSignedIn && (
					<div className={styles.header_info}>
						{!inHistory && (
							<Fragment>
								{(process.env.BUILD_MODE === 'staging' ||
									process.env.BUILD_MODE ===
										'production') && (
									<a
										href={
											process.env.BUILD_MODE === 'staging'
												? testnetPancakeSwapBuyUrl
												: pancakeSwapBuyUrl
										}
										target="_blank"
										rel="noopener noreferrer"
										className={clsx(
											styles.header_info_item,
											styles.header_pancake,
										)}
										onClick={() => {
											// navigate('./history');
										}}
									>
										<PancakeIcon />
										<div>
											<p>Buy ACT on </p>
											<p>PancakeSwap</p>
										</div>
										<div
											className={
												styles.header_pancake_mask
											}
										>
											<div
												className={
													styles.header_pancake_bg
												}
											/>
										</div>
									</a>
								)}

								<div
									className={styles.header_info_item}
									onClick={(e) => {
										e.stopPropagation();
										e.preventDefault();
										setTokenShow(!tokenShow);
									}}
								>
									<TokenIcon />
									Add Token
									<div
										className={clsx(
											tokenShow &&
												styles.header_info_token_backdrop,
										)}
										onClick={(e) => {
											e.stopPropagation();
											e.preventDefault();
											setTokenShow(false);
										}}
									/>
									<div
										className={clsx(
											styles.header_info_token,
											tokenShow &&
												styles.header_info_token_active,
										)}
									>
										<div
											className={clsx(
												styles.header_info_token_list,
												!ACT &&
													styles.header_info_token_list_disable,
											)}
											onClick={(e) => {
												e.stopPropagation();
												e.preventDefault();
												if (!wallet) return;
												const web3Provider =
													new ethers.providers.Web3Provider(
														wallet.provider,
													);
												ACT &&
													addToken(web3Provider, ACT);
											}}
										>
											<ActIcon />
											{ACT ? ACT.symbol : 'ACT'}
										</div>
										<div
											className={clsx(
												styles.header_info_token_list,
												!USDT &&
													styles.header_info_token_list_disable,
											)}
											onClick={(e) => {
												e.stopPropagation();
												e.preventDefault();
												if (!wallet) return;
												const web3Provider =
													new ethers.providers.Web3Provider(
														wallet.provider,
													);
												USDT &&
													addToken(
														web3Provider,
														USDT,
													);
											}}
										>
											<USDTIcon />
											{USDT ? USDT.symbol : 'USDT'}
										</div>
									</div>
								</div>
								<div
									className={styles.header_info_item}
									onClick={() => {
										navigate('./history');
									}}
								>
									<HistoryIcon />
									History
								</div>
							</Fragment>
						)}
						{/* <Dropdown
							className="dropdown-select dropdown-network"
							options={chainOptions}
							value={chainOptions[0]}
							arrowClassName="dropdown-arrow"
							onChange={_onChange}
						/>
						<div className={styles.header_info_address}>
							1A39e...93eqf
						</div>
						<div className={styles.header_info_logout}>
							<LogoutIcon />
						</div> */}
					</div>
				)}
			</div>
		</>
	);
}

export default Header;
