import { useEffect, useState } from 'react';
import qrcode, { QRCodeToStringOptions } from 'qrcode';
import styles from './styles.module.scss';

type IProps = {
	value: string;
	options?: QRCodeToStringOptions;
};

function QRCode({ value, options }: IProps) {
	const [img, setImg] = useState('');

	useEffect(() => {
		qrcode.toString(
			value,
			{ margin: 0, ...options, type: 'svg' },
			(error, url) => {
				!error && setImg(url);
			},
		);
	}, [value, options]);

	return (
		<div
			className={styles.root}
			dangerouslySetInnerHTML={{ __html: img }}
		/>
	);
}

export default QRCode;
