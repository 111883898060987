import bs58 from 'bs58';

export const toHexString = (chainId: number) => {
	return '0x' + chainId.toString(16);
};

export const decodeBase58Address = (base58Sting: string) => {
	if (typeof base58Sting != 'string') return undefined;

	if (base58Sting.length <= 4) return undefined;

	return `0x${Buffer.from(bs58.decode(base58Sting))
		.toString('hex')
		.substring(2, 42)}`;
};
