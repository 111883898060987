import { ethers } from 'ethers';
import { validate } from 'crypto-address-validator-ts';
import EChainType from 'constants/enums/chainType';

export const isValidAddress = (chainType: EChainType, address: string) => {
	if (chainType === EChainType.tron) {
		return validate(address, 'Tron', {
			networkType: 'both',
			chainType: '',
		});
	} else {
		return ethers.utils.isAddress(address);
	}
};
