import EActionType from 'constants/enums/action-type';
import ETokens from 'constants/enums/tokens';
import { IChainInfo } from 'constants/interfaces/chainConfig';
import ITokenConfig from 'constants/interfaces/tokenConfig';
import { Contract, ethers } from 'ethers';

export type IFormFields = {
	actionType: EActionType;
	fromChainInfo?: IChainInfo;
	toChainInfo?: IChainInfo;
	address: string;
	token?: ITokenConfig;
	amount: string;
	contract?: ethers.Contract;
};

export const initialFormData: IFormFields = {
	actionType: EActionType.deposit,
	fromChainInfo: undefined,
	toChainInfo: undefined,
	address: '',
	token: undefined,
	amount: '0',
	contract: undefined,
};

export type IWithdrawDataFields = {
	toChainInfo?: IChainInfo;
	token?: {
		type: ETokens;
		chainInfo: IChainInfo;
		symbol: string;
		contractAddress: string;
		decimals: number;
	};
	address: string;
	amount: string;
	balance: number;
	dailyAvailable: number;
	contract?: Contract;
};

export const initalWithdrawData: IWithdrawDataFields = {
	toChainInfo: undefined,
	token: undefined,
	address: '',
	amount: '0',
	balance: 0,
	dailyAvailable: 0,
	contract: undefined,
};
