import { useState } from 'react';
import { ReactComponent as CopySVG } from 'assets/icons/copy.svg';
import { ReactComponent as TickSVG } from 'assets/icons/tick.svg';
import styles from './styles.module.scss';
import clsx from 'clsx';

type IProps = {
	value: string;
};

const hiddenNode = document.createElement('div');
hiddenNode.style.position = 'fixed';
hiddenNode.style.width = '0px';
hiddenNode.style.height = '0px';

function CopyButton({ value }: IProps) {
	const unavailable = !navigator.clipboard && !window.getSelection;

	const [success, setSuccess] = useState(false);

	async function copyText() {
		if (navigator.clipboard) {
			await navigator.clipboard.writeText(value);
		} else if (window.getSelection) {
			document.body.appendChild(hiddenNode);

			hiddenNode.innerText = value;

			const range = document.createRange();
			range.selectNode(hiddenNode);

			const selection = window.getSelection();
			selection?.removeAllRanges();
			selection?.addRange(range);

			document.execCommand('copy');

			document.body.removeChild(hiddenNode);
		}
		setSuccess(true);

		setTimeout(() => {
			setSuccess(false);
		}, 1000);
	}

	return (
		<div
			className={clsx(styles.root, unavailable && styles.unavailable)}
			onClick={unavailable ? undefined : () => copyText()}
		>
			{success ? <TickSVG /> : <CopySVG />}
		</div>
	);
}

export default CopyButton;
