import { FormEvent, Fragment, useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import Modal from 'react-modal';
import { ethers } from 'ethers';
import axios from 'axios';
import bridgeContractAbi from 'constants/contract/bridge-abi.json';
import { ContractTransaction } from 'ethers';
import { useSetChain } from '@web3-onboard/react';
import { initialFormData } from './form';
import OnlineContext from '../../context';
import EActionType from 'constants/enums/action-type';
import EChainType from 'constants/enums/chainType';
import IChainConfig from 'constants/interfaces/chainConfig';
import IResponseGetChains from 'constants/interfaces/response-getChains';
import TabNav from 'components/TabNav';
import Button from 'components/Button';
import DepositForm from './components/DepositForm';
import WithdrawForm from './components/WithdrawForm';
import { submitAction as depositAction } from './components/DepositForm/form';
import { submitAction as withdrawAction } from './components/WithdrawForm/form';
import { ReactComponent as ErrorOutlineIcon } from 'assets/icons/error_outline.svg';
import styles from './styles.module.scss';
import { toHexString } from 'utils/code';

export type TransferFormProps = {
	onSubmit: () => void;
	onSend: (transactionHash: ContractTransaction['hash']) => void;
	onSuccess: () => void;
	onError: (message?: string) => void;
};

export const formDataRef = { current: initialFormData };

export const resetFormData = () =>
	(formDataRef.current = formDataRef.current =
		{
			...initialFormData,
			// symbol: formDataRef.current.symbol,
		});

function TransferForm(props: TransferFormProps) {
	const { web3Provider } = useContext(OnlineContext);
	const [{ connectedChain }, setChain] = useSetChain();
	const [modalOpen, setModalOpen] = useState<boolean>(false);
	const [modalStatus, setModalStatus] = useState<boolean>(false);
	const [supportChain, setSupportChain] = useState<IChainConfig[]>();
	const [hasSupport, setHasSupport] = useState<boolean | undefined>(false);

	// set withdraw submit button status
	const [withdrawSubmit, setWithdrawSubmit] = useState<boolean>(false);

	const chainId = connectedChain?.id;

	const closeModal = () => {
		setModalOpen(false);
	};

	const changeNetwork = async (chainId: string) => {
		try {
			const networkChanged = await setChain({
				chainId,
			});
			if (networkChanged) {
				setModalStatus(true);
				setHasSupport(true);
			}
		} catch {
			console.log('change error');
		}
	};

	const [formData, setFormData] = useState({
		...initialFormData,
		...formDataRef.current,
	});
	formDataRef.current = formData;

	// async function handleSubmit(event: FormEvent<HTMLFormElement>) {
	// 	event.preventDefault();
	// 	if (formData.actionType === EActionType.deposit) {
	// 		// depositAction({
	// 		// 	...props,
	// 		// 	recipientWallet: user.internalAddress,
	// 		// });
	// 	} else {
	// 		const symbol = withdrawDataRef.current.token;
	// 		const address =
	// 			symbol === 'USDA' || symbol === 'USDT'
	// 				? '0xFFfffffF8d2EE523a2206206994597c13D831EC7'
	// 				: '0x0000000000000000000000000000000000000800';
	// 		const contracts = new ethers.Contract(
	// 			address,
	// 			bridgeContractAbi,
	// 			web3Provider.getSigner(),
	// 		);
	// 		withdrawAction({
	// 			...props,
	// 		});
	// 	}
	// }

	// useEffect(() => {
	// 	resetFormData();
	// }, []);

	useEffect(() => {
		// get support chains and set support chains
		(async () => {
			try {
				const result = (
					await axios.get<IResponseGetChains>(
						'/secure/bridge/public/chains',
					)
				).data;
				const aminoxChains = result.filter(
					(item) => item.chainType === EChainType.aminox,
				);

				const hasSupport = aminoxChains?.some(
					(item) => toHexString(item.chainId) === chainId,
				);

				setSupportChain(aminoxChains);
				setHasSupport(hasSupport);
			} catch (e) {
				console.log(e, 'error');
			}
		})();
	}, []);

	useEffect(() => {
		// if change network success, change to withdraw tab
		if (modalStatus) {
			if (hasSupport) {
				setModalOpen(false);
				setFormData((prev) => ({
					...prev,
					actionType: EActionType.withdraw,
				}));
			}
		}
		const timer = setTimeout(() => {
			setModalStatus(false);
		}, 200);

		return () => {
			clearTimeout(timer);
		};
	}, [hasSupport, modalStatus, connectedChain]);

	useEffect(() => {
		// check chainId change is support chain, if valid , set hasSupport

		const support = supportChain?.some(
			(item) => toHexString(item.chainId) === chainId,
		);

		setHasSupport(support);
	}, [chainId, supportChain]);

	useEffect(() => {
		// if hasSupport chainId, change tab to withdraw , else change to deposit
		// check has get supportChain and hasSupport to open modal
		if (
			formData.actionType === EActionType.withdraw &&
			supportChain &&
			!hasSupport
		) {
			setFormData((prev) => ({
				...prev,
				actionType: EActionType.deposit,
			}));
			setModalOpen(true);
		}
	}, [hasSupport]);

	return (
		<div className={styles.root}>
			<Modal
				isOpen={modalOpen}
				onRequestClose={closeModal}
				className="network_modal"
				contentLabel="network Modal"
			>
				<ErrorOutlineIcon className="network_modal-icon" />
				<h3>Incorrect Network</h3>
				<p>Please select one of the following networks.</p>
				{supportChain?.map((item) => {
					return (
						<Fragment key={item.name}>
							<button
								className="network-buttons"
								onClick={() => {
									changeNetwork(toHexString(item.chainId));
								}}
							>
								{item.name}
							</button>
						</Fragment>
					);
				})}
			</Modal>
			<TabNav
				tabs={[
					{ label: 'Deposit', value: EActionType.deposit },
					{ label: 'Withdraw', value: EActionType.withdraw },
				]}
				active={formData.actionType}
				onChange={(actionType) => {
					if (
						EActionType.withdraw === actionType &&
						supportChain &&
						!hasSupport
					) {
						setModalOpen(true);
					} else {
						setFormData((prev) => ({ ...prev, actionType }));
					}
					// setFormData((prev) => ({ ...prev, actionType }));
				}}
				className={styles.tabNav}
			/>
			{formData.actionType === EActionType.deposit && (
				<DepositForm
					transfer={props}
					data={formData}
					onChange={setFormData}
				/>
			)}
			{
				// chainId === SupportChain.Amino_X_Testnet &&
				hasSupport && formData.actionType === EActionType.withdraw && (
					<>
						<WithdrawForm
							data={formData}
							onChange={setFormData}
							transfer={props}
						/>
					</>
				)
			}
		</div>
	);
}

export default TransferForm;
