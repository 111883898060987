import { ReactNode, useRef, useState, useEffect } from 'react';
import clsx from 'clsx';
import { BrowserMultiFormatReader, Result, Exception } from '@zxing/library';
import Modal from 'react-modal';
import styles from './styles.module.scss';
import { ReactComponent as CloseSVG } from 'assets/icons/clear.svg';
import Spinner from 'components/Spinner';

type IScanQRCode = {
	children?: ReactNode;
	onChange: (value: string) => void;
};

export function ScanQRcode({ children, onChange }: IScanQRCode) {
	const [modalOpen, setModalOpen] = useState(false);
	const [loading, setLoading] = useState(false);

	const videoRef = useRef(null);
	const controlRef = useRef<any>(null);

	const closeModal = () => {
		setModalOpen(false);
		if (controlRef.current) {
			controlRef.current.reset();
		}
	};

	useEffect(() => {
		return () => {
			if (controlRef.current) {
				controlRef.current.reset();
			}
		};
	}, []);

	return (
		<div className={styles.root}>
			<button
				onClick={(e) => {
					e.preventDefault();
					setModalOpen(true);
				}}
			>
				{children}
			</button>

			<Modal
				isOpen={modalOpen}
				onAfterOpen={async () => {
					const codeReader = new BrowserMultiFormatReader();

					const getLoading = setInterval(() => {
						if (videoRef.current) {
							const loading = codeReader.isVideoPlaying(
								videoRef.current,
							);
							setLoading(loading);
							if (loading) {
								clearInterval(getLoading);
							}
						}
					}, 1000);

					codeReader.decodeFromVideoDevice(
						null,
						videoRef.current,
						(result: Result, error?: Exception) => {
							controlRef.current = codeReader;

							if (error) {
								return;
							}
							if (result) {
								setModalOpen(false);
								const [_, address] = result
									.getText()
									.split(':');
								onChange(address);
								codeReader.reset();
							}
						},
					);
				}}
				onRequestClose={closeModal}
				className={styles.modal}
				contentLabel="Scan Modal"
			>
				<div className={styles.close}>
					<CloseSVG onClick={closeModal} />
				</div>
				{!loading && (
					<div className={styles.loading}>
						<Spinner />
					</div>
				)}
				<div className={styles.wrap}>
					<video
						className={clsx([loading && styles.videoShow])}
						ref={videoRef}
					/>
				</div>
			</Modal>
		</div>
	);
}

export default ScanQRcode;
