import clsx from 'clsx';
import './styles.scss';
import styles from './styles.module.scss';

interface BrandingProps {
	mini?: boolean;
}

function Branding(props: BrandingProps) {
	return (
		<div className={clsx(styles.title, props.mini && styles.mini)}>
			<h1>Bridge</h1>
			{process.env.BUILD_MODE !== 'production' && <span>Testnet</span>}
			<p>Cross-chain Asset Transfer</p>
		</div>
	);
}

export default Branding;
