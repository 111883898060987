export enum EChainTypeIdx {
	'ethereum' = 0,
	'binance',
	'aminox',
	'polygon',
	'tron',
}

// #TODO: Generate EChainType from EChainTypeIndex
// const getEChainType = (typeIdx: EChainTypeIdx) => {
// 	return EChainTypeIdx[typeIdx];
// }

enum EChainType {
	'ethereum' = 'ethereum',
	'binance' = 'binance',
	'tron' = 'tron',
	'aminox' = 'aminox',
}

export default EChainType;
