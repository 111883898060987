import { useContext, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import IResponseGetChains from 'constants/interfaces/response-getChains';
import EChainType from 'constants/enums/chainType';
import { ContractTransaction, utils, ContractReceipt } from 'ethers';
import StatusDisplay from './components/StatusDisplay';
import TransferForm, {
	formDataRef,
	resetFormData,
} from './components/TransferForm';
import { resetWithdraw } from './components/TransferForm/components/WithdrawForm';
import OnlineContext from './context';
import bridgeContractAbi from 'constants/contract/bridge-abi.json';
import EActionType from 'constants/enums/action-type';
import styles from './styles.module.scss';
import TruncatedText from 'components/TruncatedText';
import delay from 'utils/delay';

const contractInterface = new utils.Interface(bridgeContractAbi);

let timer: ReturnType<typeof setInterval>;

function Online() {
	const { web3Provider } = useContext(OnlineContext);
	const transactionHashRef = useRef('');
	const [txUrl, setTxUrl] = useState<string | undefined>();

	const [transaction, setTransaction] = useState<
		| undefined
		| Record<string, never>
		| { failed: true; message?: string }
		| Partial<ContractTransaction>
	>();

	const [receipt, setReceipt] = useState<
		undefined | null | Record<string, never> | Partial<ContractReceipt>
	>();

	const [TronTransaction, setTronTransaction] = useState<any>();

	async function updateTransaction(hash: string) {
		const transaction = await web3Provider.getTransaction(hash);
		const receipt = await web3Provider.getTransactionReceipt(hash);

		if (transaction.blockNumber && transaction.confirmations) {
			web3Provider.off(hash);
		}
		setReceipt(receipt);
		setTransaction(transaction);
	}

	async function updateTronTransaction(hash: string) {
		try {
			await delay(500);
			const transaction =
				await window.tronLink.tronWeb.trx.getTransaction(hash);
			timer = setInterval(async () => {
				const transactionInfo =
					await window.tronLink.tronWeb.trx.getTransactionInfo(hash);

				if (transactionInfo?.receipt?.result) {
					setTronTransaction(transactionInfo);
					clearInterval(timer);
				} else {
					setTronTransaction(transaction);
				}
			}, 1000);
		} catch (e) {
			console.log(e, 'e');
		}
	}

	useEffect(() => {
		return () => {
			transactionHashRef.current &&
				web3Provider.off(transactionHashRef.current);
			web3Provider.off('error');
		};
	}, []);

	useEffect(() => {
		(async () => {
			try {
				const result = (
					await axios.get<IResponseGetChains>(
						'/secure/bridge/public/chains',
					)
				).data;

				if (formDataRef.current.actionType === EActionType.deposit) {
					const fromChain = result.find(
						(chain) =>
							chain.chainType ===
							formDataRef.current.fromChainInfo?.chainType,
					);
					setTxUrl(fromChain?.blockExplorerUrl);
				} else {
					const fromChain = result.find(
						(chain) => chain.chainType === EChainType.aminox,
					);
					setTxUrl(fromChain?.blockExplorerUrl);
				}
			} catch (e) {
				console.log(e);
			}
		})();
	}, [formDataRef.current]);

	if (TronTransaction) {
		if (
			(TronTransaction?.ret &&
				TronTransaction?.ret[0].contractRet !== 'SUCCESS') ||
			TronTransaction.result === 'FAILED'
		) {
			clearInterval(timer);
			return (
				<StatusDisplay
					type="fail"
					label="Tron Transaction rejected"
					info={'Please, try again later'}
					resetState={() => {
						setTronTransaction(undefined);
						resetFormData();
						resetWithdraw();
					}}
				/>
			);
		}
		const amount = formDataRef.current.amount;
		const symbol = formDataRef.current.token?.symbol;

		if (TronTransaction?.receipt?.result === 'SUCCESS') {
			return (
				<StatusDisplay
					type="success"
					label="Transaction confirmed!"
					info={`${amount} ${symbol} were transferred`}
					resetState={() => {
						setTronTransaction(undefined);
						resetFormData();
						resetWithdraw();
					}}
				>
					{TronTransaction.id && (
						<div className={styles.txhash}>
							<span>txHash:</span>
							<a
								href={`${txUrl}/#/transaction/${TronTransaction.id}`}
								target="_blank"
								rel="noopener noreferrer"
							>
								<TruncatedText address={TronTransaction.id} />
							</a>
						</div>
					)}
				</StatusDisplay>
			);
		}
		return (
			<StatusDisplay
				type="pending"
				label={'Confirm the transaction in Wallet'}
				info={`Transfering ${Number(amount)} ${symbol}`}
			>
				{TronTransaction.txID && (
					<div className={styles.txhash}>
						<span>txHash:</span>
						<a
							href={`${txUrl}/#/transaction/${TronTransaction.txID}`}
							target="_blank"
							rel="noopener noreferrer"
						>
							<TruncatedText address={TronTransaction.txID} />
						</a>
					</div>
				)}
			</StatusDisplay>
		);
	}

	if (transaction) {
		if ('failed' in transaction || (receipt && receipt.status === 0)) {
			return (
				<StatusDisplay
					type="fail"
					label={
						receipt && receipt.status === 0
							? 'Transaction Failed'
							: 'Transaction rejected'
					}
					info={
						('failed' in transaction && transaction.message) ||
						'Please, try again later'
					}
					resetState={() => {
						setTransaction(undefined);
						setReceipt(undefined);
						resetFormData();
						resetWithdraw();
					}}
				/>
			);
		}

		let amount = formDataRef.current.amount;
		const symbol =
			// formDataRef.current.contract?.meta.symbol ||
			// formDataRef.current.symbol;
			formDataRef.current.token?.symbol;

		if (transaction.data) {
			if (formDataRef.current.actionType === EActionType.deposit) {
				const transferData = contractInterface.decodeFunctionData(
					'transfer',
					transaction.data,
				);
				amount = utils.formatUnits(
					transferData.amount,
					formDataRef.current.token!.decimals,
				);
			} else {
				const transferData = contractInterface.decodeFunctionData(
					'transferBridgedOut',
					transaction.data,
				);
				amount = utils.formatUnits(
					transferData.value,
					formDataRef.current.token!.decimals,
				);
			}
		}

		if (transaction.blockNumber && transaction.confirmations) {
			return (
				<StatusDisplay
					type="success"
					label="Transaction confirmed!"
					info={`${amount} ${symbol} were transferred`}
					resetState={() => {
						setTransaction(undefined);
						resetFormData();
						resetWithdraw();
					}}
				>
					{transaction.hash && (
						<div className={styles.txhash}>
							<span>txHash:</span>
							<a
								href={`${txUrl}/tx/${transaction.hash}`}
								target="_blank"
								rel="noopener noreferrer"
							>
								<TruncatedText address={transaction.hash} />
							</a>
						</div>
					)}
				</StatusDisplay>
			);
		}

		return (
			<StatusDisplay
				type="pending"
				label={
					formDataRef.current.actionType === EActionType.deposit
						? 'Confirm the transaction in Wallet'
						: 'Funds are being withdrawn'
				}
				info={
					formDataRef.current.actionType === EActionType.deposit
						? `Transfering ${Number(amount)} ${symbol}`
						: `Withdrawing ${Number(amount)} ${symbol}`
				}
			>
				{transaction.hash && (
					<div className={styles.txhash}>
						<span>txHash:</span>
						<a
							href={`${txUrl}/tx/${transaction.hash}`}
							target="_blank"
							rel="noopener noreferrer"
						>
							<TruncatedText address={transaction.hash} />
						</a>
					</div>
				)}
			</StatusDisplay>
		);
	}

	return (
		<TransferForm
			onSubmit={() => {
				formDataRef.current.fromChainInfo?.chainType === EChainType.tron
					? setTronTransaction({})
					: setTransaction({});
			}}
			onSend={async (transactionHash) => {
				if (
					formDataRef.current.fromChainInfo?.chainType ===
					EChainType.tron
				) {
					transactionHashRef.current = transactionHash;
					updateTronTransaction(transactionHash);
				} else {
					transactionHashRef.current = transactionHash;
					updateTransaction(transactionHash);

					web3Provider
						.once('error', () => {
							setTransaction({ failed: true });
							web3Provider.off(transactionHash);
						})
						.on(transactionHash, () => {
							updateTransaction(transactionHash);
							web3Provider.off('error');
						});
				}
			}}
			onSuccess={() =>
				setTransaction({ blockNumber: 7777777, confirmations: 7777777 })
			}
			onError={(message) =>
				formDataRef.current.fromChainInfo?.chainType === EChainType.tron
					? setTronTransaction({ result: 'FAILED' })
					: setTransaction({ failed: true, message })
			}
		/>
	);
}

export default Online;
