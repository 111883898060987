import {
	Dispatch,
	SetStateAction,
	useContext,
	useEffect,
	useState,
} from 'react';
import { ethers } from 'ethers';
import clsx from 'clsx';
import Dropdown from 'react-dropdown';
import InputSelect from 'components/InputSelect';
import { useSetChain } from '@web3-onboard/react';
import { useFormik } from 'formik';
import useService from 'hooks/useService';
import Button from 'components/Button';
import InputText from 'components/InputText';
import QRCode from 'components/QRCode';
import CopyButton from './components/CopyButton';
import OnlineContext from 'Bridge/views/Online/context';
import StatusDisplay from 'Bridge/views/Online/components/StatusDisplay';
import { IFormFields } from '../../form';
import axios from 'axios';
import contractAbi from 'constants/contract/abi.json';
import IResponseGetChains from 'constants/interfaces/response-getChains';
import IResponseGetTokens from 'constants/interfaces/response-getTokens';
import IChainConfig from 'constants/interfaces/chainConfig';
import ITokenConfig from 'constants/interfaces/tokenConfig';
import EChainType from 'constants/enums/chainType';
// import { ReactComponent as LinkSvg } from 'assets/icons/open_in_new.svg';
import { ReactComponent as CloseSvg } from 'assets/icons/clear.svg';
import { toTokenUrl } from 'utils/url';
import styles from './styles.module.scss';
import { toHexString } from 'utils/code';
import { submitAction as DepositAction } from '../DepositForm/form';
import { TransferFormProps } from '../../index';
import EActionType from 'constants/enums/action-type';
import WarningModal from 'components/WarningModal';
import {
	TronLinkWarning,
	ConnectWalletWarning,
	NetworkWarning,
} from './warning-data';
import { validation } from './validation';

type IProps = {
	transfer: TransferFormProps;
	data: IFormFields;
	onChange: Dispatch<SetStateAction<IProps['data']>>;
};

type IChainOption = {
	label: string;
	value: string;
	meta: IChainConfig;
	USDTContractAddress?: string;
	ACTContractAddress?: string;
};

type ITokenOption = {
	label: string;
	value: string;
	meta: ITokenConfig;
};

type requestAccountsResponse = {
	// #NOTE: code
	// 200: ok
	// 4000: in the queue
	// 4001: user rejected
	code: number;
	message: string;
};

function DepositForm({ transfer, data, onChange }: IProps) {
	const { chainId, web3Provider } = useContext(OnlineContext);

	const [{ connectedChain }, setChain] = useSetChain();

	const [_, { respErrorHandler }] = useService();

	const [fromChainOptions, setFromChainOptions] =
		useState<Array<IChainOption>>();
	const [toChainOptions, setToChainOptions] = useState<Array<IChainOption>>();
	const [sourceChain, setSourceChain] = useState<IChainConfig>();
	const [destinationChain, setDestinationChain] = useState<IChainConfig>();
	const [tokenOptions, setTokenOptions] = useState<Array<ITokenOption>>();
	const [qrShow, setQrShow] = useState<boolean>(false);

	const [modalContent, setModalContent] = useState(TronLinkWarning);
	const [isModalOpen, setIsModalOpen] = useState(false);

	const handleSubmit = async (values: any) => {
		if (!fromChainOptions || !tokenOptions) {
			return;
		}
		const fromChain = fromChainOptions[values.fromChain].meta;
		const token = tokenOptions[values.token].meta;
		const contract = await new ethers.Contract(
			token.contractAddress,
			contractAbi,
			web3Provider.getSigner(),
		);
		onChange({
			actionType: EActionType.deposit,
			fromChainInfo: {
				chainType: fromChain.chainType,
				chainId: fromChain.chainId,
			},
			address: values.toAddress,
			token,
			amount: values.amount,
			contract,
		});
		const isTron = fromChain.chainType === EChainType.tron;

		if (isTron) {
			if (!window.tronLink) {
				setModalContent(TronLinkWarning);
				setIsModalOpen(true);
			} else {
				try {
					const res: requestAccountsResponse =
						await window.tronLink.request({
							method: 'tron_requestAccounts',
						});
					if (!res) {
						setModalContent(ConnectWalletWarning);
						setIsModalOpen(true);
					} else if (res.code === 200) {
						const node =
							await window.tronLink.tronWeb.trx.getNodeInfo();
						// #NOTE: p2pVersion
						// 1: shasta
						// 11111: tron mainnet
						if (
							Number(node.configNodeInfo.p2pVersion) ===
							fromChain.chainId
						) {
							transfer.onSubmit();
							const contract = await window.tronLink.tronWeb
								.contract()
								.at(token.contractAddress);

							const result = await contract
								.transfer(
									formik.values.toAddress,
									parseInt(
										ethers.utils.parseUnits(
											values.amount,
											token.decimals,
										)._hex,
									),
								)
								.send({
									feeLimit:
										window.tronLink.tronWeb.feeLimit ||
										50_000_000,
								})
								.then((output: any) => {
									transfer.onSend(output);
								})
								.catch((e: any) => {
									transfer.onError(undefined);
								});
						} else {
							setModalContent(NetworkWarning);
							setIsModalOpen(true);
						}
					}
				} catch (e) {
					setModalContent(ConnectWalletWarning);
					setIsModalOpen(true);
				}
			}
		} else {
			DepositAction({ ...transfer });
		}
	};

	const formik = useFormik({
		initialValues: {
			fromChain: undefined,
			toAddress: undefined,
			token: undefined,
			amount: '0',
		},
		onSubmit: (values) => handleSubmit(values),
		validationSchema: validation,
	});

	function setField<Field extends keyof typeof data>(name: Field) {
		return (value: typeof data[Field]) => {
			onChange((prev) => ({ ...prev, [name]: value }));
		};
	}

	const changeNetwork = async (
		chainConfig: IChainConfig,
		onChange: () => void,
	) => {
		if (chainConfig.chainType == EChainType.tron) {
			onChange && onChange();
		} else {
			const chainId = toHexString(chainConfig.chainId);
			try {
				const networkChanged = await setChain({
					chainId,
				});
				if (networkChanged) {
					console.log('network switched');
					onChange && onChange();
				}
			} catch (e) {
				console.log('change error', e);
			}
		}
	};

	useEffect(() => {
		if (fromChainOptions && formik.values.fromChain) {
			const chainConfig = fromChainOptions[formik.values.fromChain].meta;
			const chainId = toHexString(chainConfig.chainId);
			if (connectedChain?.id !== chainId) {
				// Clear fromChain when connected chain switched
				formik.setFieldValue('fromChain', undefined);
				setSourceChain(undefined);
			}
		}
	}, [connectedChain]);

	useEffect(() => {
		const controller = new AbortController();

		(async () => {
			try {
				const result = (
					await axios.get<IResponseGetChains>(
						'/secure/bridge/public/chains',
					)
				).data;

				// #NOTE: Disable ethereum under staging
				if (
					process.env.BUILD_MODE === 'staging' ||
					process.env.BUILD_MODE === 'production'
				) {
					const ethereumIdx = result.findIndex(
						(chain) => chain.chainType === EChainType.ethereum,
					);
					const ethereumChains =
						ethereumIdx >= 0 ? result.splice(ethereumIdx, 1) : [];
				}

				const aminoxIdx = result.findIndex(
					(chain) => chain.chainType === EChainType.aminox,
				);
				const aminoxChains =
					aminoxIdx >= 0 ? result.splice(aminoxIdx, 1) : [];

				const chainOptions = result.map((chain, index) => ({
					label: chain.name,
					value: `${index}`,
					meta: chain,
				}));

				const aminoxChainOptions = aminoxChains.map((chain, index) => ({
					label: chain.name,
					value: `${index}`,
					meta: chain,
				}));

				setFromChainOptions(chainOptions);
				setToChainOptions(aminoxChainOptions);
			} catch (e) {
				//
			}
		})();

		return () => {
			controller.abort();
		};
	}, []);

	useEffect(() => {
		if (!sourceChain) {
			formik.setFieldValue('toAddress', '');
			return;
		}
		(async () => {
			try {
				const result = (
					await axios.get<IResponseGetTokens>(
						'/secure/bridge/public/tokens',
					)
				).data;

				const tokens = Object.entries(result)
					.map(([type, list]) => {
						const fromChainTokenConfig = list.find(
							(token) =>
								token.chainInfo.chainId ===
									sourceChain.chainId &&
								token.chainInfo.chainType ===
									sourceChain.chainType,
						);
						if (fromChainTokenConfig) {
							return {
								type,
								config: list.find(
									(token) =>
										token.chainInfo.chainId ===
										sourceChain.chainId,
								),
							};
						} else {
							return {
								type,
								config: undefined,
							};
						}
					})
					.filter((item) => item.config !== undefined)
					.map((token, index) => ({
						value: `${index}`,
						label: token.config?.symbol,
						meta: token.config,
					})) as ITokenOption[];
				setTokenOptions(tokens);
			} catch (e) {
				respErrorHandler(e);
				console.log(e);
			}

			try {
				const { depositAddress } = (
					await axios.get(
						`/secure/bridge/depositAddress/${sourceChain.chainType}/${sourceChain.chainId}`,
					)
				).data;
				formik.setFieldValue('toAddress', depositAddress);
				setQrShow(true);
			} catch (e: any) {
				respErrorHandler(e);
				formik.setFieldValue('toAddress', undefined);
			}
		})();
	}, [sourceChain]);

	if (!fromChainOptions || !toChainOptions) {
		return (
			<StatusDisplay type={'pending'} label={'Loading chains'} info="" />
		);
	}

	const isSelectedChain = Boolean(
		sourceChain && formik.values.toAddress && tokenOptions,
	);

	return (
		<form className={styles.root} onSubmit={formik.handleSubmit}>
			<WarningModal
				title={modalContent.title}
				content={modalContent.content}
				isOpen={isModalOpen}
				onClose={() => {
					setIsModalOpen(false);
				}}
				onConfirm={() => {
					setIsModalOpen(false);
				}}
			/>
			<InputSelect
				label="From"
				className={styles.input}
				options={fromChainOptions}
				value={formik.values.fromChain || ''}
				placeholder={'Select Network'}
				onChange={(index) => {
					const chainConfig = fromChainOptions[parseInt(index)].meta;
					changeNetwork(chainConfig, () => {
						formik.setFieldValue('fromChain', index);
						setSourceChain(chainConfig);
					});
				}}
			/>
			<div className={styles.label}>To</div>
			<Dropdown
				className="dropdown-style"
				disabled
				options={toChainOptions}
				value={toChainOptions[0]?.value}
				arrowClassName="dropdown-style-arrow-disable"
				controlClassName="dropdown-style-contorl"
				menuClassName="dropdown-style-menu"
				onChange={(item) => {
					setDestinationChain(
						toChainOptions[parseInt(item.value)].meta,
					);
				}}
			/>

			<div className={styles.line} />
			<InputText
				className={isSelectedChain ? '' : 'disabled'}
				label="Address"
				value={formik.values.toAddress}
				readOnly
				disabled={!isSelectedChain}
				suffix={
					<>
						{formik.values.toAddress ? (
							<>
								<CopyButton value={formik.values.toAddress} />
							</>
						) : null}
						<p
							className={clsx(styles.showQrCode, [
								!isSelectedChain && styles.disabled,
							])}
							onClick={() => {
								if (!isSelectedChain) return;
								setQrShow(true);
							}}
						>
							Show QR Code
						</p>
					</>
				}
			/>
			<InputSelect
				label="Token"
				className={clsx(styles.input, [!isSelectedChain && 'disabled'])}
				options={tokenOptions ? tokenOptions : []}
				placeholder={isSelectedChain ? 'Select' : ''}
				value={formik.values.token || ''}
				disabled={!isSelectedChain}
				onChange={(index) => {
					formik.setFieldValue('token', index);
				}}
				error={
					formik.touched.token && formik.errors.token
						? formik.errors.token
						: undefined
				}
			/>
			<InputText
				className={isSelectedChain ? '' : 'disabled'}
				label="Amount"
				name="amount"
				onlyNumbers
				disabled={!isSelectedChain}
				value={formik.values.amount}
				onChange={(value) => {
					formik.setFieldValue('amount', value);
				}}
				error={
					formik.touched.amount && formik.errors.amount
						? formik.errors.amount
						: undefined
				}
			/>
			<Button
				type="submit"
				className={clsx(
					styles.submitBtn,
					// (!formik.isValid || !formik.dirty) &&
					// styles.submitBtn_disable,
				)}
				disabled={!isSelectedChain}
				// disabled={!formik.isValid || !formik.dirty}
			>
				Send
			</Button>
			{qrShow && isSelectedChain && formik.values.toAddress && (
				<div className={styles.onlyUSDT}>
					<div className={styles.close}>
						<CloseSvg
							onClick={() => {
								setQrShow(!qrShow);
							}}
						/>
					</div>
					<div className={styles.qrcode}>
						<QRCode value={formik.values.toAddress} />
					</div>
					<div className={styles.text}>
						<span>This address only accepts:</span>
						{tokenOptions &&
							tokenOptions.map((item: ITokenOption) => {
								if (!sourceChain) return;
								const blockUrl = toTokenUrl(
									item.meta.chainInfo.chainType,
									sourceChain.blockExplorerUrl,
									item.meta.contractAddress,
								);
								return (
									<a
										href={blockUrl}
										target="_blank"
										rel="noopener noreferrer"
										key={item.label}
									>
										{/* <LinkSvg />  */}
										{item.label}
									</a>
								);
							})}
					</div>
				</div>
			)}
			{/* ) : (
				<StatusDisplay
					type={'pending'}
					label={'Please select chain'}
					info=""
				/>
			)} */}
		</form>
	);
}

export default DepositForm;
