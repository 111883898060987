import { ReactNode } from 'react';

type ITruncatedText = {
	className?: string;
	address: string | undefined;
	children?: ReactNode;
};

function truncateText(str: string | undefined) {
	if (str) {
		return (
			str.substr(0, 6) + '...' + str.substr(str.length - 4, str.length)
		);
	}
	return undefined;
}

export default function TruncatedText({
	className,
	address,
	children,
}: ITruncatedText) {
	return (
		<div className={className}>
			{truncateText(address)}
			{children}
		</div>
	);
}
