/* eslint-disable @typescript-eslint/no-explicit-any */
import type { InputText } from 'components/InputText';
import type { InputSelect } from 'components/InputSelect';
import styles from './styles.module.scss';
import './styles.scss';
import clsx from 'clsx';

type Inputs = typeof InputText | typeof InputSelect;

type IProps<Input extends Inputs> = Parameters<Input>[0] & {
	label?: string;
	className?: string;
};

function InputLabel<Input extends Inputs>(Input: Input) {
	return function LabeledInput({
		label,
		className,
		...props
	}: IProps<Input>) {
		return (
			<div className={clsx(styles.root, className)}>
				<div className={styles.label}>{label}</div>
				<Input {...(props as any)} />
			</div>
		);
	};
}

export default InputLabel;
