import { createContext } from 'react';
// import IUser from 'constants/interfaces/user';
import { ethers } from 'ethers';
import { NetworkInfo } from 'hooks/useNetwork';

export type IOnlineContext = {
	address: string;
	token: string;
	id: string;
	// user: IUser;
	web3Provider: ethers.providers.Web3Provider;
} & NetworkInfo;

const OnlineContext = createContext<IOnlineContext>({} as IOnlineContext);

export default OnlineContext;
