import Button from 'components/Button';
import Icon from 'components/Icon';
import Spinner from 'components/Spinner';
import { ReactComponent as TickSVG } from 'assets/icons/tick.svg';
import { ReactComponent as CrossSVG } from 'assets/icons/cross.svg';
import styles from './styles.module.scss';

type IProps = {
	type: 'pending' | 'fail' | 'success';
	label: string;
	info: string;
	children?: React.ReactNode;
	resetState?: () => void;
};

function StatusDisplay({ type, label, info, children, resetState }: IProps) {
	return (
		<div className={styles.root}>
			{type === 'pending' ? (
				<Spinner />
			) : (
				<Icon icon={type === 'success' ? TickSVG : CrossSVG} />
			)}
			<h2 className={styles.title}>{label}</h2>
			<div className={styles.info}>{info}</div>
			{children}
			{resetState && (
				<Button onClick={() => resetState()} className={styles.button}>
					Return to Bridge
				</Button>
			)}
		</div>
	);
}

export default StatusDisplay;
