import React from 'react';
import ReactDOM from 'react-dom';
import App from 'App';
import 'assets/styles/index.scss';
import reportWebVitals from 'reportWebVitals';
import { BrowserRouter } from 'react-router-dom';

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			{/* Remove after demo */}
			{/* <button
			type="button"
			onClick={() => {
				document.body.className = document.body.className.includes(
					'theme',
				)
					? ''
					: 'theme-dark';
			}}
			style={{
				position: 'absolute',
				left: '50%',
				top: 10,
				transform: 'translateX(-50%)',
				backgroundColor: 'white',
				padding: 10,
				borderRadius: 10,
				boxShadow: '0 0 5px 0 grey',
			}}
		>
			Toggle theme
		</button> */}
			<App />
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
