import EChainType from 'constants/enums/chainType';

export const toTxHash = (chainType: EChainType, txHash: string) => {
	if (chainType === EChainType.tron) {
		return txHash.replace(/^(0x)/, '');
	} else {
		return txHash;
	}
};

export const toTxUrl = (
	chainType: EChainType,
	blockExplorerUrl: string,
	txHash: string,
) => {
	if (chainType === EChainType.tron) {
		return `${blockExplorerUrl}/#/transaction/${txHash}`;
	} else {
		return `${blockExplorerUrl}/tx/${txHash}`;
	}
};

export const toTokenUrl = (
	chainType: EChainType,
	blockExplorerUrl: string,
	contractAddress: string,
) => {
	if (chainType === EChainType.tron) {
		return `${blockExplorerUrl}/#/token20/${contractAddress}`;
	} else {
		return `${blockExplorerUrl}/token/${contractAddress}`;
	}
};
