import ENetworks from 'constants/enums/networks';
import IConfig from 'constants/types/config';

const config: IConfig = {
	// API_URL: 'http://192.168.50.153:8080/',
	API_URL: 'https://staging.bridge.sapi.alphacarbon.network/',
	ONBOARD_API_KEY: '',
	DEFAULT_NETWORK: ENetworks.ropsten,
};

export default config;
