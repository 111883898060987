enum ENetworks {
	'main' = 1,
	'ropsten' = 3,
	'rinkeby' = 4,
	'goerli' = 5,
	'boba_rinkeby' = 28,
	'kovan' = 42,
	'bsc' = 56,
	'xdai' = 100,
	'polygon' = 137,
	'fantom_opera' = 250,
	'boba' = 288,
	'fantom_testnet' = 4000,
	'leucine_100' = 31337,
}

export default ENetworks;
