import { InputHTMLAttributes, ReactNode } from 'react';
import clsx from 'clsx';
import InputLabel from 'HOCs/InputLabel';
import styles from './styles.module.scss';

type IProps = {
	onlyNumbers?: boolean;
	unit?: string;
	suffix?: ReactNode;
	error?: string;
	onChange?: (value: string) => void;
} & Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'>;

function InputText({
	error,
	suffix,
	unit,
	onlyNumbers,
	onChange,
	...props
}: IProps) {
	return (
		<div className={styles.out}>
			<div
				className={clsx(
					styles.root,
					error && styles.root_hasError,
					suffix && styles.root_hasSuffix,
				)}
			>
				<label>
					<input
						{...props}
						onChange={
							!onChange
								? undefined
								: (event) => {
										let value = event.target.value;

										if (onlyNumbers) {
											value = value
												.replaceAll(',', '.')
												.replaceAll(/([^.\d])/g, '')
												.replaceAll(
													/^0+(\d|0.)/g,
													'$1',
												);
										}

										const dots = value.split('.');
										if (dots.length > 1) {
											value = '';
											dots.forEach((item, i) => {
												if (i === dots.length - 1) {
													value += '.';
												}

												value += item;
											});
										}
										onChange(value);
								  }
						}
					/>
					<span>{props.value}</span>
					{unit && <div>{unit}</div>}
				</label>
				{suffix}
			</div>
			<div className={styles.error}>{error}</div>
		</div>
	);
}

export default InputLabel(InputText);

export type { InputText };
