import * as Yup from 'yup';
import { isValidAddress } from 'utils/address';

export const validation = Yup.object().shape({
	toChain: Yup.string().required('Required'),
	toChainType: Yup.string(),
	address: Yup.string()
		.required('Required')
		.when('toChainType', (toChainType, schema) => {
			if (toChainType !== undefined) {
				return schema.test(
					'isValidAddress',
					'Not a valid address',
					function (value: any) {
						return isValidAddress(toChainType, value);
					},
				);
			}
		}),
	token: Yup.string().required('Required'),
	amount: Yup.number()
		.required('Required')
		.typeError('must be number')
		.min(0, 'amount must be greater than 0')
		// #TODO: Add back withdrawal limit check after server update
		// .when('dailyAvailable', (dailyAvailable, schema) => {
		// 	return schema.test(
		// 		'dailyLimit',
		// 		'exceed withdraw limit',
		// 		function (value: number) {
		// 			return value <= dailyAvailable;
		// 		},
		// 	);
		// })
		.when('balance', (balance, schema) => {
			return schema.test(
				'balanceCheck',
				'Insufficient funds.',
				function (value: number) {
					return value <= balance;
				},
			);
		}),
	balance: Yup.number(),
	dailyAvailable: Yup.number(),
	// fee: Yup.number(),
});
