import { ReactComponent as SVG } from './assets/spinner.svg';
import styles from './styles.module.scss';

function Spinner() {
	return (
		<div className={styles.root}>
			<div>
				<SVG width={undefined} height={undefined} />
			</div>
		</div>
	);
}

export default Spinner;
