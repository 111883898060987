import jwt, { JwtPayload } from 'jsonwebtoken';

export const decode = (token: string) => {
	return jwt.decode(token) as JwtPayload;
};

export const isJwtExpired = (token: string) => {
	const decodedJwt = jwt.decode(token) as JwtPayload;

	// exp timestamp is milliseconds
	const isExpired = decodedJwt.exp
		? Date.now() >= decodedJwt.exp * 1000
		: false;
	return isExpired;
};
