// import Icon from 'components/Icon';
import Spinner from 'components/Spinner';
// import { ReactComponent as TickSVG } from 'assets/icons/tick.svg';
// import { ReactComponent as CrossSVG } from 'assets/icons/cross.svg';
import { toHexString } from 'utils/code';
import INetworkConfig from 'constants/interfaces/networkConfig';
import { ReactComponent as ErrorOutlineIcon } from 'assets/icons/error_outline.svg';
import styles from './styles.module.scss';

type SetChainOptions = {
	chainId: string;
	chainNamespace?: string;
};

type IProps = {
	type: 'pending' | 'fail' | 'success';
	label: string;
	info: string;
	networkList: undefined | [] | INetworkConfig[];
	setChain: (options: SetChainOptions) => Promise<boolean>;
};

function NetworkDisplay({ type, label, info, networkList, setChain }: IProps) {
	const changeNetwork = async (chainId: string) => {
		await setChain({
			chainId,
		});
	};

	return (
		<div className={type === 'pending' ? styles.root : styles.network}>
			{type === 'pending' ? <Spinner /> : <ErrorOutlineIcon />}
			<h2 className={styles.title}>{label}</h2>
			<div className={styles.info}>{info}</div>
			<>
				{type !== 'pending' &&
					networkList &&
					networkList.map((network, idx) => (
						<button
							key={idx}
							className="network-buttons"
							onClick={() => {
								changeNetwork(toHexString(network.chainId));
							}}
						>
							{network.name}
						</button>
					))}
			</>
		</div>
	);
}

export default NetworkDisplay;
